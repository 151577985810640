import {
  Badge,
  Button,
  CopyCapsule,
  HStack,
  MinList,
  MinListControls,
  MinListItemFooterLink,
  MinListItemHeader,
  MinListItemLabel,
  MinListItemPair,
  MinListItemValue,
  MinListTitle,
  Subheading2,
  VStack,
} from '@meterup/atto';
import { checkDefinedOrThrow, isDefined } from '@meterup/common';
import { api } from '@meterup/proto';
import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { fetchClientsJSON } from '../../api/clientsApi';
import { paths } from '../../constants';
import { isGuest, isOffline, isOnline } from '../../utils/clients';
import { getGrafanaURL } from '../../utils/grafana';
import { makeDrawerLink, makeLink } from '../../utils/makeLink';
import { getEnabledRadioForBandOrNull } from '../../utils/radios';
import { DeviceStatusIcon } from '../DeviceStatusIcon';
import {
  DisabledBadge,
  EnabledBadge,
  NeutralBadge,
  OnlineOfflineClientCountBadge,
} from '../Network/badges';

export interface RadioDetailsProps {
  title: string;
  radio?: api.AccessPointRadioResponse | null;
}

const EnabledRadioDetailsContent = ({ radio }: { radio: api.AccessPointRadioResponse }) => (
  <>
    <MinListItemPair>
      <MinListItemLabel>Status</MinListItemLabel>
      <MinListItemValue>
        <EnabledBadge arrangement="leading-icon" icon="checkmarkCircle" />
      </MinListItemValue>
    </MinListItemPair>
    <MinListItemPair>
      <MinListItemLabel>Channel</MinListItemLabel>
      <MinListItemValue>
        <Badge size="small">{radio.channel}</Badge>
      </MinListItemValue>
    </MinListItemPair>
    <MinListItemPair>
      <MinListItemLabel>Width</MinListItemLabel>
      <MinListItemValue>
        <Badge size="small">{radio.channel_width}</Badge>
      </MinListItemValue>
    </MinListItemPair>
    <MinListItemPair>
      <MinListItemLabel>Power</MinListItemLabel>
      <MinListItemValue>
        <Badge size="small">{radio.power}</Badge>
      </MinListItemValue>
    </MinListItemPair>
  </>
);

const DisabledRadioDetailsContent = () => (
  <MinListItemPair>
    <MinListItemLabel>Status</MinListItemLabel>
    <MinListItemValue>
      <DisabledBadge arrangement="leading-icon" icon="crossCircle" />
    </MinListItemValue>
  </MinListItemPair>
);

const WirelessBandDetails = ({ title, radio }: RadioDetailsProps) => (
  <MinList>
    <MinListItemHeader icon="wifi">
      <MinListTitle>{title}</MinListTitle>
    </MinListItemHeader>
    {isDefined(radio) && !radio.disabled ? (
      <EnabledRadioDetailsContent radio={radio} />
    ) : (
      <DisabledRadioDetailsContent />
    )}
  </MinList>
);

export default function DeviceDetailContent({
  controllerName,
  device,
}: {
  device: api.AccessPointRadiosResponse;
  controllerName: string;
}) {
  const accessPoint = checkDefinedOrThrow(device.access_point);

  const clients =
    useQuery(['clients', controllerName, accessPoint.id], () =>
      fetchClientsJSON(controllerName, accessPoint.id),
    ).data ?? [];

  const onlineClients = clients.filter(isOnline);
  const userClients = onlineClients.filter((c) => !isGuest(c));
  const guestClients = onlineClients.filter(isGuest);
  const recentClients = clients.filter(isOffline);

  return (
    <>
      <VStack align="center" spacing={10 as any}>
        <HStack>
          <DeviceStatusIcon value={accessPoint.status} />
        </HStack>
        <Subheading2>
          <CopyCapsule
            textValue={accessPoint.id}
            aria-label="Copy access point name"
            arrangement="leading-label"
          >
            {accessPoint.id}
          </CopyCapsule>
        </Subheading2>
      </VStack>

      <MinList>
        <MinListItemHeader icon="device">
          <MinListTitle>Details</MinListTitle>
          <MinListControls>
            <Button
              as={Link}
              to={makeDrawerLink(paths.drawers.DeviceEditLocation, {
                controllerName,
                id: accessPoint.id,
              })}
              variant="secondary"
              size="small"
            >
              Edit
            </Button>
          </MinListControls>
        </MinListItemHeader>
        <MinListItemPair>
          <MinListItemLabel>Version</MinListItemLabel>
          <MinListItemValue>
            <Badge size="small">{accessPoint.version}</Badge>
          </MinListItemValue>
        </MinListItemPair>
        <MinListItemPair>
          <MinListItemLabel>Location</MinListItemLabel>
          <MinListItemValue>{accessPoint.location}</MinListItemValue>
        </MinListItemPair>
      </MinList>

      <MinList
        as={Link}
        to={makeLink(paths.pages.ControllerDeviceClients, {
          controllerName,
          deviceName: accessPoint.id,
        })}
      >
        <MinListItemHeader icon="client" hasArrow>
          <MinListTitle>Clients</MinListTitle>
        </MinListItemHeader>
        <MinListItemPair>
          <MinListItemLabel>Online</MinListItemLabel>
          <MinListItemValue>
            <OnlineOfflineClientCountBadge icon="wifi" value={onlineClients.length} />
          </MinListItemValue>
        </MinListItemPair>
        <MinListItemPair>
          <MinListItemLabel>Users</MinListItemLabel>
          <MinListItemValue>
            <OnlineOfflineClientCountBadge icon="wifi" value={userClients.length} />
          </MinListItemValue>
        </MinListItemPair>
        <MinListItemPair>
          <MinListItemLabel>Guests</MinListItemLabel>
          <MinListItemValue>
            <OnlineOfflineClientCountBadge icon="wifi" value={guestClients.length} />
          </MinListItemValue>
        </MinListItemPair>

        <MinListItemPair>
          <MinListItemLabel>Recent</MinListItemLabel>
          <MinListItemValue>
            <NeutralBadge
              arrangement="leading-icon"
              icon={recentClients.length > 0 ? 'client' : undefined}
            >
              {recentClients.length}
            </NeutralBadge>
          </MinListItemValue>
        </MinListItemPair>
        <MinListItemFooterLink as="div">View clients</MinListItemFooterLink>
      </MinList>

      <Button
        variant="tertiary"
        width="full"
        size="large"
        as="a"
        href={getGrafanaURL(controllerName, accessPoint.id)}
        target="_blank"
        icon="reporting"
        arrangement="leading-icon"
      >
        Open Grafana
      </Button>

      <WirelessBandDetails
        title="5G band"
        radio={getEnabledRadioForBandOrNull(device, api.RadioBand.RB_5G)}
      />
      <WirelessBandDetails
        title="2.4G band"
        radio={getEnabledRadioForBandOrNull(device, api.RadioBand.RB_2G)}
      />
    </>
  );
}
