import {
  Alert,
  Body2,
  Button,
  CloseDrawerButton,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@meterup/atto';
import { checkDefinedOrThrow } from '@meterup/common';
import { partition } from 'lodash';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import type { InviteUsersFormData } from './InviteUsersFormData';
import { createUserJSON, fetchCompanyUsersJSON } from '../../../../../api/usersApi';
import { Nav } from '../../../../../components/Nav';
import { UserRoleBadge } from '../../../../../components/Network/badges';
import { paths } from '../../../../../constants';
import { getErrorMessage } from '../../../../../errors/errors';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { fontWeights, styled } from '../../../../../stitches';
import { makeDrawerLink } from '../../../../../utils/makeLink';
import { extractUniqueEmailAddressesFromDelimitedString } from './InviteUsersFormData';

const SimpleList = styled('ul', {
  listStyle: 'none',
  margin: 0,
  padding: 0,
});

const SimpleListItem = styled('li', Body2, {
  listStyleType: 'disc',
  fontWeight: fontWeights.medium,
  marginLeft: '$20',
  paddingLeft: '$4',
});

export const ConfirmationView = ({
  values,
  onClickBack,
}: {
  values: InviteUsersFormData;
  onClickBack: () => void;
}) => {
  const { companyName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.UsersAdd),
  );

  const navigate = useNavigate();

  const closeDrawer = useCloseDrawerCallback();

  const client = useQueryClient();

  const users =
    useQuery(['company', companyName, 'users'], () => fetchCompanyUsersJSON(companyName), {
      suspense: true,
    }).data ?? [];

  const existingEmails = users.map((user) => user.email);

  const [toInvite, existing] = partition(
    extractUniqueEmailAddressesFromDelimitedString(values.emailListRawText),
    (email) => !existingEmails.includes(email),
  );

  const createUserMutation = useMutation(
    ['create_users', toInvite, values.company_role],
    async () =>
      Promise.all(
        toInvite.map((email) =>
          createUserJSON(companyName, {
            email,
            company_role: values.company_role,
          }),
        ),
      ),
    {
      onSuccess: (createdUsers) => {
        client.invalidateQueries(['company', companyName, 'users']);

        if (createdUsers.length === 1 && createdUsers[0]) {
          const user = createdUsers[0];
          navigate(makeDrawerLink(paths.drawers.UserDetail, { companyName, userSid: user.sid }));
        } else {
          closeDrawer();
        }
      },
    },
  );

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>Add users</DrawerTitle>
        <DrawerControls>
          <CloseDrawerButton />
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        {createUserMutation.error && (
          <Alert
            variant="negative"
            icon="warning"
            heading="Unexpected error"
            copy={getErrorMessage(createUserMutation.error) ?? 'An unexpected error occurred'}
          />
        )}
        {toInvite.length > 0 && (
          <>
            <Body2>
              The following email addresses will be added to the <strong>{companyName}</strong>{' '}
              company with <UserRoleBadge value={values.company_role} /> permissions:
            </Body2>
            <SimpleList>
              {toInvite.map((email) => (
                <SimpleListItem key={email}>{email}</SimpleListItem>
              ))}
            </SimpleList>
          </>
        )}
        {existing.length > 0 && (
          <>
            <Body2>
              The following email addresses already belong to the <strong>{companyName}</strong>{' '}
              company and won't be added again:
            </Body2>
            <SimpleList>
              {existing.map((email) => (
                <SimpleListItem key={email}>{email}</SimpleListItem>
              ))}
            </SimpleList>
          </>
        )}
      </DrawerContent>
      <DrawerFooter>
        <DrawerControls>
          <Button
            variant="secondary"
            type="button"
            disabled={createUserMutation.isLoading}
            onClick={onClickBack}
          >
            Back
          </Button>
          <Button
            loading={createUserMutation.isLoading}
            onClick={() => createUserMutation.mutate()}
          >
            {toInvite.length > 0 ? 'Add' : 'Close'}
          </Button>
        </DrawerControls>
      </DrawerFooter>
    </Drawer>
  );
};
