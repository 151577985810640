import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTriggerButton,
} from '@meterup/atto';
import { checkDefinedOrThrow, expectDefinedOrThrow, isDefinedAndNotEmpty } from '@meterup/common';
import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { fetchDeviceJSON } from '../../../../api/devicesApi';
import { CloseDrawerButton } from '../../../../components/CloseDrawerButton/CloseDrawerButton';
import { DeleteDeviceDialog } from '../../../../components/Device/DeleteDeviceDialog';
import DeviceDetailContent from '../../../../components/Device/DeviceDetailContent';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { ResourceNotFoundError } from '../../../../errors/errors';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/devices/:id',
});

export default function DeviceDetail() {
  const { controllerName, id } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.DeviceDetail),
  );

  const device = useQuery(
    ['controller', controllerName, 'devices', id],
    () => fetchDeviceJSON(controllerName, id),
    { suspense: true, enabled: isDefinedAndNotEmpty(id) },
  ).data;

  expectDefinedOrThrow(device, new ResourceNotFoundError('Access point not found'));

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>Access point</DrawerTitle>
        <DrawerControls>
          <DropdownMenu>
            <DropdownMenuTriggerButton
              arrangement="hidden-label"
              icon="overflowVertical"
              variant="tertiary"
            >
              Actions
            </DropdownMenuTriggerButton>
            <DropdownMenuContent>
              <DropdownMenuItem onSelect={() => setIsOpen(true)}>
                Remove access point
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <CloseDrawerButton />
        </DrawerControls>
      </DrawerHeader>

      <DrawerContent>
        <DeviceDetailContent controllerName={controllerName} device={device} />
      </DrawerContent>

      <DeleteDeviceDialog
        controllerName={controllerName}
        deviceId={id}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
      />
    </Drawer>
  );
}
