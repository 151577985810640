import type { CellProps, Column } from 'react-table';
import { HStack, Icon, List, ListItemHeader, ListTitle, space } from '@meterup/atto';
import { expectDefinedOrThrow } from '@meterup/common';
import React from 'react';
import { useQuery } from 'react-query';

import type { LegacyNetworkInfo } from '../utils/legacy_network_info';
import { fetchNetworkInfoJSON } from '../api/controllersApi';
import { paths } from '../constants';
import { ControllerRequestFailedError } from '../errors/errors';
import {
  LegacyNetworkPSKSchemeType,
  networkInfoAPIResponseToList,
} from '../utils/legacy_network_info';
import { makeDrawerLink } from '../utils/makeLink';
import { AutoTable } from './AutoTable/AutoTable';
import { ListItemTableContainer } from './ListItemTableContainer';
import { ListItemViewAllLink } from './ListItemViewAllLink';
import { NeutralBadge } from './Network/badges';
import { Tooltip } from './Tooltip/Tooltip';

const columns: Column<LegacyNetworkInfo>[] = [
  {
    Header: 'SSID',
    accessor: (d) => d.ssid,
    Cell: (props: CellProps<LegacyNetworkInfo, string>) => (
      <HStack align="center" spacing={space(4)}>
        {props.value} {props.row.original.isSSIDDisabled && <NeutralBadge>Disabled</NeutralBadge>}
        {props.row.original.isCustomKey && (
          <Tooltip content="This SSID is custom and cannot be modified through NOC">
            <Icon icon="attention" />
          </Tooltip>
        )}
      </HStack>
    ),
  },
  {
    Header: 'Password',
    accessor: (d) => d.passwordScheme,
    Cell: (props: CellProps<LegacyNetworkInfo, LegacyNetworkPSKSchemeType>) => (
      <NeutralBadge>
        {props.value === LegacyNetworkPSKSchemeType.Rotating ? 'Rotating' : 'Static'}
      </NeutralBadge>
    ),
  },
];

const Passwords = ({ controllerName }: { controllerName: string }) => {
  const networkPass = useQuery(
    ['controllers', controllerName, 'legacy-network-info'],
    async () => fetchNetworkInfoJSON(controllerName),
    {
      suspense: true,
      retry: false,
    },
  ).data;

  expectDefinedOrThrow(
    networkPass,
    new ControllerRequestFailedError(controllerName, 'network_info'),
  );

  const list = networkInfoAPIResponseToList(networkPass);

  return (
    <List>
      <ListItemHeader>
        <ListTitle>Wireless networks</ListTitle>
      </ListItemHeader>
      <ListItemTableContainer>
        <AutoTable columns={columns} data={list} shouldShowTopBar={false} />
      </ListItemTableContainer>
      <ListItemViewAllLink
        to={makeDrawerLink(paths.drawers.LegacyNetworkInfo, {
          controllerName,
        })}
      >
        View details
      </ListItemViewAllLink>
    </List>
  );
};

export default Passwords;
