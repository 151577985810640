import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Skeleton } from '@meterup/atto';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router';

import { DrawerRoutes } from '../../../components/DrawerRoutes';
import { Nav } from '../../../components/Nav';
import { paths } from '../../../constants';
import { ConfigEditorProvider } from '../../../context/ConfigEditorContext';
import { ControllerPageFooter } from '../../../ControllerPageHeader/ControllerPageFooter';
import { ControllerPageHeader } from '../../../ControllerPageHeader/ControllerPageHeader';
import { useCanUseConfigAPIForController } from '../../../hooks/useCanUseConfigAPIForController';
import { ControllerPageLayout } from '../../../layouts/ControllerPageLayout';
import { MainAndDrawerLayout } from '../../../layouts/MainAndDrawerLayout';

const ControllerPageConfigUIGate = ({
  children,
  controllerName,
}: {
  children: React.ReactNode;
  controllerName: string;
}) => {
  const canShowConfigUI = useCanUseConfigAPIForController(controllerName);

  return canShowConfigUI ? (
    <ConfigEditorProvider controllerName={controllerName}>{children}</ConfigEditorProvider>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};

const ControllerPageWapper = ({ children }: { children: React.ReactNode }) => {
  const params = Nav.useRegionParams('root', paths.pages.ControllerDetails);

  return params?.controllerName ? (
    <ControllerPageConfigUIGate controllerName={params.controllerName}>
      {children}
    </ControllerPageConfigUIGate>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName',
  layout: null,
});

export default function ControllerPage() {
  return (
    <Suspense
      fallback={
        <div style={{ padding: 16 }}>
          <Skeleton width="100%" height="240px" radius={8} />
        </div>
      }
    >
      <ControllerPageWapper>
        <ControllerPageLayout header={<ControllerPageHeader />} footer={<ControllerPageFooter />}>
          <MainAndDrawerLayout main={<Outlet />} drawer={<DrawerRoutes />} />
        </ControllerPageLayout>
      </ControllerPageWapper>
    </Suspense>
  );
}
