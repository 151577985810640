import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  BasicSelect,
  BasicSelectItem,
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  SecondaryField,
} from '@meterup/atto';
import { checkDefinedOrThrow, expectDefinedOrThrow } from '@meterup/common';
import { api } from '@meterup/proto';
import { Formik } from 'formik';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { fetchCompanyUserJSON, updateUserJSON } from '../../../../api/usersApi';
import { CloseDrawerButton } from '../../../../components/CloseDrawerButton/CloseDrawerButton';
import { FieldProvider } from '../../../../components/FieldProvider';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { ResourceNotFoundError } from '../../../../errors/errors';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { styled } from '../../../../stitches';
import { makeDrawerLink } from '../../../../utils/makeLink';
import { validUserRole } from '../../../../utils/users';
import { UserNameAndEmailWidget } from './common/UserNameAndEmailWidget';

export const Meta: PagefileMetaFn = () => ({
  path: '/companies/:companyName/users/:userSid/edit',
});

const StyledForm = styled('form', {
  display: 'contents',
});

const validUpdateUserData = z.object({
  company_role: validUserRole,
});

type ValidUpdateUserData = z.infer<typeof validUpdateUserData>;

export default function UserEdit() {
  const { companyName, userSid } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.UserDetail),
  );

  const user = useQuery(
    ['company', companyName, 'users', userSid],
    () => fetchCompanyUserJSON(companyName, userSid),
    { suspense: true },
  ).data;

  expectDefinedOrThrow(user, new ResourceNotFoundError(`User ${userSid} not found`));

  const navigate = useNavigate();
  const closeDrawer = useCloseDrawerCallback();
  const client = useQueryClient();

  const updateUserMutation = useMutation(
    (data: api.UserUpdateRequest) => updateUserJSON(companyName, userSid, data),
    {
      onSuccess: () => {
        closeDrawer();
        client.invalidateQueries(['company', companyName, 'users']);
      },
    },
  );

  return (
    <Formik<ValidUpdateUserData>
      validationSchema={toFormikValidationSchema(validUpdateUserData)}
      initialValues={{
        company_role:
          user.company_role === api.CompanyMembershipRole.unknown
            ? api.CompanyMembershipRole.guest
            : user.company_role,
      }}
      onSubmit={(data) => {
        updateUserMutation.mutate({
          company_role: data.company_role,
          active_user_token_limit_changed: false,
          role: undefined as any,
        });
      }}
    >
      {(form) => (
        <StyledForm onSubmit={form.handleSubmit}>
          <Drawer>
            <DrawerHeader>
              <DrawerTitle>Edit user</DrawerTitle>
              <DrawerControls>
                <CloseDrawerButton />
              </DrawerControls>
            </DrawerHeader>

            <DrawerContent>
              <UserNameAndEmailWidget user={user} />
              <FieldProvider name="company_role">
                <SecondaryField
                  label="Choose a role"
                  element={
                    <BasicSelect>
                      <BasicSelectItem value={api.CompanyMembershipRole.guest}>
                        Guest
                      </BasicSelectItem>
                      <BasicSelectItem value={api.CompanyMembershipRole.member}>
                        Member
                      </BasicSelectItem>
                      <BasicSelectItem value={api.CompanyMembershipRole.admin}>
                        Admin
                      </BasicSelectItem>
                    </BasicSelect>
                  }
                />
              </FieldProvider>
            </DrawerContent>
            <DrawerFooter>
              <DrawerControls>
                <Button
                  variant="secondary"
                  onClick={() =>
                    navigate(makeDrawerLink(paths.drawers.UserDetail, { companyName, userSid }))
                  }
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  Save
                </Button>
              </DrawerControls>
            </DrawerFooter>
          </Drawer>
        </StyledForm>
      )}
    </Formik>
  );
}
