import type { ReactNode } from 'react';
import { Alert, BodyMono2, Button } from '@meterup/atto';
import { isDefined } from '@meterup/common';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { uploadAccessPointsCSV } from '../../api/devicesApi';
import { getErrorMessage } from '../../errors/errors';
import { styled } from '../../stitches';
import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogCloseButton,
  DialogContent,
  DialogControls,
  DialogFooter,
  DialogHeader,
  DialogSection,
  DialogTitle,
  DialogTrigger,
} from '../Dialog/Dialog';
import { FilePickerInput } from './FilePickerInput';

const CSVPreviewText = styled('pre', BodyMono2, {
  maxHeight: 200,
  overflow: 'auto',
});

export function APCSVUploadDialog({
  trigger,
  controllerName,
}: {
  controllerName: string;
  trigger: ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [fileForUpload, setFileForUpload] = useState<File | null>(null);

  const queryClient = useQueryClient();

  const uploadFileMutation = useMutation(
    async (file: File) => {
      await uploadAccessPointsCSV(controllerName, file);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['controller', controllerName]);
        setIsOpen(false);
        setFileForUpload(null);
      },
    },
  );

  const fileText = useQuery(
    `${fileForUpload?.name}-${fileForUpload?.lastModified}-${fileForUpload?.size}`,
    () => fileForUpload?.text(),
  );

  const uploadFile = async (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    if (isDefined(fileForUpload)) {
      uploadFileMutation.mutate(fileForUpload);
    }
  };

  const errorMessage = isDefined(uploadFileMutation.error)
    ? getErrorMessage(uploadFileMutation.error)
    : null;

  const handleOpenChange = (value: boolean) => {
    if (!value) {
      setFileForUpload(null);
      uploadFileMutation.reset();
    }
    setIsOpen(value);
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent>
        <form onSubmit={uploadFile} style={{ display: 'contents' }}>
          <DialogHeader>
            <DialogTitle>Import access points</DialogTitle>
            <DialogControls>
              <DialogCloseButton />
            </DialogControls>
          </DialogHeader>
          <DialogBody>
            {isDefined(errorMessage) && (
              <Alert
                icon="warning"
                variant="negative"
                heading="Upload failed"
                copy={errorMessage}
                cornerStyle="square"
              />
            )}
            <DialogSection>
              <FilePickerInput accept=".csv" value={fileForUpload} onChange={setFileForUpload} />
            </DialogSection>
            {isDefined(fileText.data) && <CSVPreviewText>{fileText.data}</CSVPreviewText>}
          </DialogBody>
          <DialogFooter>
            <DialogControls>
              <DialogClose asChild>
                <Button variant="secondary">Cancel</Button>
              </DialogClose>
              <Button loading={uploadFileMutation.isLoading} disabled={!isDefined(fileForUpload)}>
                Import
              </Button>
            </DialogControls>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
