import { Badge } from '@meterup/atto';
import { capitalize } from 'lodash';
import React from 'react';

import {
  CATEGORY_DEINSTALLATION,
  CATEGORY_INSTALLATION,
  CATEGORY_MAINTENANCE,
  CATEGORY_OUTAGE,
} from '../../routes/drawers/controllers/incidents/common/form_data';

type IncidentCategoryBadgeProps = {
  category: string;
};

export const IncidentCategoryBadge = ({ category }: IncidentCategoryBadgeProps) => {
  if (
    category === CATEGORY_INSTALLATION ||
    category === CATEGORY_MAINTENANCE ||
    category === CATEGORY_DEINSTALLATION
  ) {
    return (
      <Badge size="small" variant="alternative">
        {capitalize(category)}
      </Badge>
    );
  }
  if (category === CATEGORY_OUTAGE) {
    return (
      <Badge size="small" variant="negative">
        {capitalize(category)}
      </Badge>
    );
  }
  return (
    <Badge size="small" variant="neutral">
      None
    </Badge>
  );
};
