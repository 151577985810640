import type { api } from '@meterup/proto';
import {
  Body2,
  CopyCapsule,
  List,
  ListItemHeader,
  ListItemLabel,
  ListItemPair,
  ListItemValue,
  ListTitle,
} from '@meterup/atto';
import { isDefined } from '@meterup/common';
import React from 'react';

import { PendingBadge, UserRoleBadge } from '../../../../../components/Network/badges';
import { formatFullName } from '../../../../../utils/users';

export const UserProfileAndPermissionsWidget = ({ user }: { user: api.UserResponse }) => {
  const formattedName = formatFullName(user);
  return (
    <List>
      <ListItemHeader>
        <ListTitle>Profile & permissions</ListTitle>
      </ListItemHeader>
      <ListItemPair>
        <ListItemLabel>Name</ListItemLabel>
        <ListItemValue>
          <Body2>
            {isDefined(formattedName) ? (
              <CopyCapsule
                aria-label="Copy full name"
                arrangement="leading-icon"
                textValue={formattedName}
              >
                {formattedName}
              </CopyCapsule>
            ) : (
              <PendingBadge />
            )}
          </Body2>
        </ListItemValue>
      </ListItemPair>
      <ListItemPair>
        <ListItemLabel>Email</ListItemLabel>
        <ListItemValue>
          <Body2>
            <CopyCapsule aria-label="Copy email" arrangement="leading-icon" textValue={user.email}>
              {user.email}
            </CopyCapsule>
          </Body2>
        </ListItemValue>
      </ListItemPair>
      <ListItemPair>
        <ListItemLabel>Role</ListItemLabel>
        <ListItemValue>
          <UserRoleBadge value={user.company_role} />
        </ListItemValue>
      </ListItemPair>
    </List>
  );
};
